import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="min-h-screen flex flex-col dark:bg-gray-900 dark:text-white font-serif" data-is-root-path={isRootPath}>
      <header className="flex justify-center items-center py-5 bg-black text-white">
        <Link className="text-2xl" to="/">
          {title}
        </Link>
      </header>

      <main className="mb-auto">{children}</main>

      <footer className="flex justify-center items-center py-3 bg-black text-white">
        ©&nbsp;{new Date().getFullYear()}, Built with&nbsp;<a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
